<template>
  <div>
    <div class="container">
      <el-descriptions :title="count_list_name_title" direction="vertical" :column="4" border></el-descriptions>
      <div>
        <el-button type="primary" @click="handleAdd">增加一个服务器</el-button>

      </div>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="cloud_name" label="云厂商">
        <template slot-scope="scope">
          <span v-if="!scope.row.editing" :style="{ color: rowColor(scope.row) }">{{ scope.row.cloud_provider }}</span>
          <el-input v-else v-model="scope.row.tmp_cloud_provider" placeholder="请输入任务备注"></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="account_name" label="登录账号">
        <template slot-scope="scope">
          <span v-if="!scope.row.editing" :style="{ color: rowColor(scope.row) }">{{ scope.row.login_account }}</span>
          <el-input v-else v-model="scope.row.tmp_login_account" placeholder="请输入任务备注"></el-input>
        </template>
      </el-table-column>
       <el-table-column prop="ip" label="ip地址">
        <template slot-scope="scope">
          <span v-if="!scope.row.editing" :style="{ color: rowColor(scope.row) }">{{ scope.row.ip_address }}</span>
          <el-input v-else v-model="scope.row.tmp_ip_address" placeholder="请输入任务备注"></el-input>
        </template>

      </el-table-column>
      <el-table-column prop="opendport" label="开放端口">
        <template slot-scope="scope">
          <span v-if="!scope.row.editing" :style="{ color: rowColor(scope.row) }">{{ scope.row.open_ports }}</span>
          <el-input v-else v-model="scope.row.tmp_open_ports" placeholder="请输入任务备注"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="function" label="用途">
        <template slot-scope="scope">
          <span v-if="!scope.row.editing" :style="{ color: rowColor(scope.row) }">{{ scope.row.purpose }}</span>
          <el-input v-else v-model="scope.row.tmp_purpose" placeholder="请输入任务备注"></el-input>
        </template>
      </el-table-column>


      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.editing" type="primary" size="mini" @click="startEditing(scope.row)">编辑
          </el-button>
          <el-button v-else type="success" size="mini" @click="confirmEditing(scope.row)">确认</el-button>
          <el-button v-if="scope.row.editing" type="danger" size="mini" @click="cancelEditing(scope.row)">取消</el-button>
          <el-popconfirm v-if="!scope.row.editing" title="确定删除这一行吗？" confirmButtonText="确定" cancelButtonText="取消"
                         @confirm="handleDelete(scope.row)">
            <el-button type="danger" slot="reference" size="mini">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="添加一个服务器"
        :visible.sync="addTaskDialogVisible">

      <el-form :model="formData" label-width="200px" size="medium" label-position="left">
        <div class="task_lunar">
          <el-form-item label="云厂商">
          <el-input v-model="formData.cloud_provider" type="textarea"></el-input>
        </el-form-item>
        </div>

        <div class="task_lunar">
          <el-form-item label="登录账号">
          <el-input v-model="formData.login_account" type="textarea"></el-input>
        </el-form-item>
        </div>

        <div class="task_lunar">
          <el-form-item label="ip地址">
          <el-input v-model="formData.ip_address" type="textarea"></el-input>
        </el-form-item>
        </div>

        <div class="task_lunar">
          <el-form-item label="开放端口">
          <el-input v-model="formData.open_ports" type="textarea"></el-input>
        </el-form-item>
        </div>
        <div class="task_lunar">
          <el-form-item label="用途">
          <el-input v-model="formData.purpose" type="textarea"></el-input>
        </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTaskDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        v-if="errorMessage"
        title="编辑结果"
        :visible.sync="dialogVisible">
      <span>{{ errorMessage }}</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from '@/config';
// import LunarCalendar from 'lunar-calendar';

export default {
  data() {
    return {
      count_list_name_title: '云服务器列表',
      tableData: [],
      formData: {
        cloud_provider: '',
        login_account: '',
        ip_address: '',
        open_ports: '',
        purpose: '',
      },
      errorMessage: '',
      dialogVisible: false,
      addTaskDialogVisible: false,

    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
  },

  methods: {

    async fetchData() {
      try {
        const response = await axios.get('/server_list');
        const responseData = response.data;
        if (responseData.code === '0000') {
          this.tableData = responseData.data.map(server => ({
            ...server,
            tmp_ip_address: server.ip_address,
            editing: false,
            tmp_cloud_provider: server.cloud_provider,
            tmp_login_account: server.login_account,
            tmp_open_ports: server.open_ports,
            tmp_purpose: server.purpose,
          }));

        } else {
          this.showErrorDialog(responseData.msg);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        this.showErrorDialog('请求数据时出错');
      }
    },

    rowColor(row) {
      const taskEndTime = new Date(row.task_end_time);
      const currentDate = new Date();
      currentDate.setHours(8)
      currentDate.setHours(1)
      currentDate.setMinutes(0)

      const timeDiff = taskEndTime.getTime() - currentDate.getTime();
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      if (timeDiff < 0) {
        return 'gray'; // 灰色
      } else if (daysDiff <= 7) {
        return '#FF0000'; // 红色
      } else if (daysDiff <= 15) {
        return '#FFA500'; // 橙色
      } else if (daysDiff <= 30) {
        return 'green'; // 黄色
      } else {
        return 'black'; // 绿色
      }
    },
    handleAdd() {
      this.addTaskDialogVisible = true;
    },
    async handleConfirm() {
      try {

        const response = await axios.post('/add_one_server', this.formData);
        const responseData = response.data;
        if (responseData.code === '0000') {
          this.addTaskDialogVisible = false;
          this.fetchData();
        } else {
          this.showErrorDialog(responseData.msg);
        }
      } catch (error) {
        console.error('Error adding task:', error);
        this.showErrorDialog('添加任务时出错');
      }
    },

    startEditing(row) {
      row.editing = true;
    },
    async confirmEditing(row) {
      try {
        const response = await axios.put(`/update_one_server/${row.id}`, {
          cloud_provider: row.tmp_cloud_provider,
          login_account: row.tmp_login_account,
          ip_address: row.tmp_ip_address,
          open_ports: row.tmp_open_ports,
          purpose: row.tmp_purpose
        });
        const responseData = response.data;
        if (responseData.code === '0000') {
          row.editing = false;
          this.fetchData();
          this.showErrorDialog('编辑成功');
        } else {
          this.showErrorDialog('后端接口报错' + responseData.msg);
        }
      } catch (error) {
        console.error('Error editing task:', error);
        this.showErrorDialog('编辑任务时出错' + error);
      }
    },
    cancelEditing(row) {
      row.editing = false;
      row.tmp_ip_address = row.ip_address;
      row.tmp_cloud_provider = row.cloud_provider;
      row.tmp_login_account = row.login_account;
      row.tmp_open_ports = row.open_ports;
      row.tmp_purpose = row.purpose;
    },
    async handleDelete(row) {
      try {
        const response = await axios.delete(`/delete_server/${row.id}`);
        const responseData = response.data;
        if (responseData.code === '0000') {
          this.fetchData();
        } else {
          this.showErrorDialog(responseData.msg);
        }
      } catch (error) {
        console.error('Error deleting task:', error);
        this.showErrorDialog('删除任务时出错');
      }
    },
    showErrorDialog(message) {
      this.errorMessage = message;
      this.dialogVisible = true;
    },
    showDeleteConfirm(row) {
      this.$confirm('确认删除此任务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleDelete(row);
      }).catch(() => {
        // 取消删除
      });
    }
  }
};
</script>

<style>
.container {
  display: flex;
  justify-content: space-between;
}

.task_lunar {
  display: flex;
  justify-content: space-between;
}

.el-table .expired-task {
  background-color: #d3d3d3; /* 灰色 */
  z-index: 1; /* 设置灰色行的 z-index */
}

.el-table .near-deadline-task {
  background: #ffcccc; /* 浅红色 */
}

.el-table .soon-deadline-task {
  background: #ffffcc; /* 黄色 */
}

.el-table .upcoming-deadline-task {
  background: palegreen; /* 绿色 */
}

.el-table .normal-task {
  /* 默认样式 */
  background: #7FFFD4;
}

label {
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;
}


</style>